import { createBreakpoints } from '@mui/system'

const breakpoints = createBreakpoints({})

export const appTheme = {
  palette: {
    type: 'light',
    primary: {
      main: '#F22D31',
    },
    secondary: {
      main: '#4381C1',
    },
    background: {
      default: '#FDFDFC',
      paper: '#ffffff',
      tag: '#F5F5F5',
    },
    text: {
      primary: '#110D0F',
      secondary: '#4B4B4B',
      hint: '#989494',
    },
  },
  typography: {
    fontFamily: 'Kanit',
    fontSize: 15,
    fontWeightLight: 200,
    fontWeightRegular: 300,
    fontWeightMedium: 400,
    fontWeightBold: 600,
    h1: {
      fontSize: '4.5rem',
      fontWeight: 700,
      lineHeight: 1,
      letterSpacing: '0.5px',
      textTransform: 'uppercase',
      marginBottom: '1.5rem',
      [breakpoints.down('sm')]: {
        fontSize: '3rem',
      },
    },
    h2: {
      fontSize: '2.2rem',
      fontWeight: 700,
      marginBottom: '0.625rem',
      [breakpoints.down('sm')]: {
        fontSize: '1.8rem',
      },
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 700,
      marginBottom: '0.625rem',
      [breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    subtitle1: {
      fontSize: '1.25rem',
      fontWeight: 400,
      lineHeight: 1.3,
      marginBottom: '1rem',
      [breakpoints.down('sm')]: {
        fontSize: '1.125rem',
      },
    },
    subtitle2: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
      marginBottom: '1rem',
    },
    body1: {
      fontSize: '0.9375rem',
      [breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.8125rem',
    },
    overline: {
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: 1,
      letterSpacing: '0.5px',
      textTransform: 'uppercase',
      marginBottom: '0.875rem',
      paddingBottom: '0.75rem',
      borderBottom: '4px solid #F22D31',
      display: 'inline-block',
    },
    overlineSmaller: {
      fontSize: '0.875rem',
      fontWeight: 900,
      lineHeight: 1,
      letterSpacing: '0px',
      textTransform: 'uppercase',
      marginBottom: '0.75rem',
      paddingBottom: '0.75rem',
      borderBottom: '4px solid #F22D31',
      display: 'inline-block',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: '5rem',
          textTransform: 'none',
          fontWeight: 600,
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem',
          [breakpoints.down('sm')]: {
            fontSize: '1rem',
          },
          ...(ownerState.size === 'large' && {
              paddingTop: '0.6rem',
              paddingBottom: '0.6rem',
              fontSize: '1.125rem',
            }),
          ...(ownerState.variant === 'outlined' && {
              borderWidth: '2px',
              '&:hover': {
                borderWidth: '2px',
              }
            }),
        }),
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          [breakpoints.down('sm')]: {
            fontSize: '1rem',
          },
          ...(ownerState.size === 'large' && {
              paddingTop: '0.3rem',
              paddingBottom: '0.3rem',
              fontSize: '1.125rem',
              [breakpoints.down('sm')]: {
                fontSize: '1rem',
              },
            }),
        }),
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: '8px',
        }),
      }
    },
  }
};