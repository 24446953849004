import React from 'react'
import Context from 'utils/Context'
import * as AppReducer from 'store/reducers'

export function ContextStateWrapper({ children }) {

  // Map the state and dispatch to our AppReducer
  const [state, dispatch] = React.useReducer(
    AppReducer.AppReducer,
    AppReducer.initialState
  )

  return (
    // Create the Provider Wrapper
    <Context.Provider
      value={{
        isAuthenticated: state.get('isAuthenticated'),
        basicDataFetched: state.get('basicDataFetched'),
        userData: state.get('userData'),
        paymentsConfig: state.get('paymentsConfig'),
        customerGroups: state.get('customerGroups'),
        currentlyEditedTag: state.get('currentlyEditedTag'),
        tagListReloadRequired: state.get('tagListReloadRequired'),
        tagChangesMade: state.get('tagChangesMade'),
        dispatch: dispatch,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export function useAppContext() {
  return React.useContext(ContextStateWrapper)
}