import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { appTheme } from 'utils/ThemeSettings'
import { CssBaseline } from '@mui/material';

export default function AppThemeWrapper({ children }) {

  const theme = createTheme(appTheme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}