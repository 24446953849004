import Immutable from 'immutable'
import * as ACTION_TYPES from './actionTypes'

export const doLogin = () => {
  return {
    type: ACTION_TYPES.LOGIN,
  }
}

export const doLogout = () => {
  return {
    type: ACTION_TYPES.LOGOUT,
  }
}

export const setBasicDataFetched = (value) => {
  return {
    type: ACTION_TYPES.SET_BASIC_DATA_FETCHED,
    payload: value,
  }
}

export const setUserData = (userData) => {
  return {
    type: ACTION_TYPES.SET_USER_DATA,
    payload: userData,
  }
}

export const setCurrentlyEditedTagContent = (tagContent) => {
  return {
    type: ACTION_TYPES.SET_CURRENTLY_EDITED_TAG_CONTENT,
    payload: tagContent,
  }
}

export const setNoTagChangesMade = () => {
  return {
    type: ACTION_TYPES.SET_NO_TAG_CHANGES_MADE,
  }
}

export const setCustomerGroups = (groups) => {
  return {
    type: ACTION_TYPES.SET_CUSTOMER_GROUPS,
    payload: groups,
  }
}

export const setTagListReloadRequired = (value) => {
  return {
    type: ACTION_TYPES.SET_TAG_LIST_RELOAD_REQUIRED,
    payload: value,
  }
}

export const setPaymentsConfig = (config) => {
  return {
    type: ACTION_TYPES.SET_PAYMENTS_CONFIG,
    payload: Immutable.fromJS(config),
  }
}