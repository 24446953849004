import Immutable from 'immutable'
import * as ACTION_TYPES from '../actions/actionTypes'

export const initialState = Immutable.fromJS({
  isAuthenticated: undefined,
  basicDataFetched: false,
  tagListReloadRequired: false,
  userData: {},
  paymentsConfig: {},
  customerGroups: [],
  currentlyEditedTag: Immutable.fromJS({}),
  tagChangesMade: false,
})

export const AppReducer = (state = initialState, action) => {
  switch (action.type) {

    case ACTION_TYPES.LOGIN:
      return (
        state.setIn(['isAuthenticated'], true)
      )

    case ACTION_TYPES.LOGOUT:
      return (
        state.setIn(['isAuthenticated'], false)
      )

    case ACTION_TYPES.SET_USER_DATA:
      return (
        state.setIn(['userData'], Immutable.fromJS(action.payload))
      )

    case ACTION_TYPES.SET_BASIC_DATA_FETCHED:
      return (
        state.setIn(['basicDataFetched'], action.payload)
      )

    case ACTION_TYPES.SET_CURRENTLY_EDITED_TAG_CONTENT:
      return (
        state.setIn(['currentlyEditedTag'], action.payload).setIn(['tagChangesMade'], true)
      )

    case ACTION_TYPES.SET_CUSTOMER_GROUPS:
      return (
        state.setIn(['customerGroups'], Immutable.fromJS(action.payload))
      )

    case ACTION_TYPES.SET_TAG_LIST_RELOAD_REQUIRED:
      return (
        state.setIn(['tagListReloadRequired'], action.payload)
      )

    case ACTION_TYPES.SET_NO_TAG_CHANGES_MADE:
      return (
        state.setIn(['tagChangesMade'], false)
      )

    case ACTION_TYPES.SET_PAYMENTS_CONFIG:
      return (
        state.setIn(['paymentsConfig'], action.payload)
      )
      
    default:
      return state
      
  }
}