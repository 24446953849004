import { useRouter } from 'next/router'
import React from 'react'
import { doLogin, doLogout, setUserData } from 'store/actions/actions'
import { authenticatedDataFetch } from './AuthenticatedDataFetch'
import Context from './Context'

export default function AuthStatusDataWrapper({ children }) {

  const [needsCheck, setNeedsCheck] = React.useState(true);

  const context = React.useContext(Context);
  const router = useRouter();

  const fetchUserData = async () => {
    try {
      const userData = await authenticatedDataFetch({
        endpointUrl: '/api/auth/user/',
        method: 'GET',
        context: context,
      });
      if (userData && !userData.error) {
        context.dispatch(setUserData(userData));
        context.dispatch(doLogin());
      } else {
        context.dispatch(doLogout());
      }
    } catch (error) {
      context.dispatch(doLogout());
    }
    setNeedsCheck(false);
  }

  React.useEffect(() => {
    if (needsCheck || !context.basicDataFetched) {
      fetchUserData();
    }
  }, [context.isAuthenticated, context.basicDataFetched])

  return (
    <div>
      {children}
    </div>
  )
}