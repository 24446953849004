import axios from 'axios'
import Cookies from 'js-cookie';
import getConfig from 'next/config'


const { publicRuntimeConfig } = getConfig();

export const authenticatedDataFetch = async ({ endpointUrl, data, method, context, contentType = 'application/json' }) => {

  try {
    axios.defaults.xsrfHeaderName = "X-CSRFToken";

    const response = await axios({
      method: method,
      url: endpointUrl,
      baseURL: publicRuntimeConfig.backendUrl,
      data: data,
      withCredentials: true,
      // TODO: No need to add csrf for HEADER
      headers: {
        "X-CSRFToken": `${Cookies.get('csrftoken')}`,
        "Content-Type": contentType,
      }
    })

    return response?.data

  } catch (error) {
    if (error.response?.status === 403) {
      return {
        error: 403
      }
    } else throw new Error('Server communication error', { cause: error.response });
  }

}
