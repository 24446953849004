import Head from 'next/head'
import 'styles/baseStyles.scss'
import ErrorBoundary from 'utils/ErrorBoundary'
import TransitionEffect from 'utils/PageTransitionWrapper'
import { ContextStateWrapper } from '../store/contextConfig'
import AuthStatusDataWrapper from '../utils/AuthStatusDataWrapper'
import AppThemeWrapper from '../utils/ThemeWrapper'

export default function MyTagsFrontendApp({ Component, pageProps }) {
  return (
    <ErrorBoundary>
      <ContextStateWrapper>
        <AppThemeWrapper>
          <AuthStatusDataWrapper>
            <TransitionEffect>
              <Head>
                <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png" />
                <link rel="manifest" href="/favicons/site.webmanifest" />
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#ffffff" />
              </Head>
              <Component {...pageProps} />
            </TransitionEffect>
          </AuthStatusDataWrapper>
        </AppThemeWrapper>
      </ContextStateWrapper>
    </ErrorBoundary>
  )
}
